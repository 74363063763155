import React from "react"
import PropTypes from "prop-types"
import Poem from "../../../../components/poem"
import NotationSolution from "../../../../components/notation-solution"

const PoemFruehlingNotationSolution = ({ size }) => {
  return (
    <Poem size={size}>
      <p>
        <strong>
          <NotationSolution id="q-01">Der</NotationSolution>{" "}
          <NotationSolution id="q-02">Frühling</NotationSolution>.
        </strong>
      </p>
      <br />
      <p>
        <NotationSolution id="q-03">Wenn</NotationSolution>{" "}
        <NotationSolution id="q-04">aus</NotationSolution>{" "}
        <NotationSolution id="q-05">der</NotationSolution>{" "}
        <NotationSolution id="q-06">Tiefe</NotationSolution>{" "}
        <NotationSolution id="q-07">kommt</NotationSolution>{" "}
        <NotationSolution id="q-08">der</NotationSolution>{" "}
        <NotationSolution id="q-09">Frühling</NotationSolution>{" "}
        <NotationSolution id="q-10">in</NotationSolution>{" "}
        <NotationSolution id="q-11">das</NotationSolution>{" "}
        <NotationSolution id="q-12">Leben</NotationSolution>,
      </p>
      <p>
        <NotationSolution id="q-13">Es</NotationSolution>{" "}
        <NotationSolution id="q-14">wundert</NotationSolution>{" "}
        <NotationSolution id="q-15">sich</NotationSolution>{" "}
        <NotationSolution id="q-16">der</NotationSolution>{" "}
        <NotationSolution id="q-17">Mensch</NotationSolution>,{" "}
        <NotationSolution id="q-18">und</NotationSolution>{" "}
        <NotationSolution id="q-19">neue</NotationSolution>{" "}
        <NotationSolution id="q-20">Worte</NotationSolution>{" "}
        <NotationSolution id="q-21">streben</NotationSolution>{" "}
      </p>
      <p>
        <NotationSolution id="q-22">Aus</NotationSolution>{" "}
        <NotationSolution id="q-23">Geistigkeit</NotationSolution>,{" "}
        <NotationSolution id="q-24">die</NotationSolution>{" "}
        <NotationSolution id="q-25">Freude</NotationSolution>{" "}
        <NotationSolution id="q-26">kehret</NotationSolution>{" "}
        <NotationSolution id="q-27">wieder</NotationSolution>{" "}
      </p>
      <p>
        <NotationSolution id="q-28">Und</NotationSolution>{" "}
        <NotationSolution id="q-29">festlich</NotationSolution>{" "}
        <NotationSolution id="q-30">machen</NotationSolution>{" "}
        <NotationSolution id="q-31">sich</NotationSolution>{" "}
        <NotationSolution id="q-32">Gesang</NotationSolution>{" "}
        <NotationSolution id="q-33">und</NotationSolution>{" "}
        <NotationSolution id="q-34">Lieder</NotationSolution>.
      </p>
      <br />
      <p>
        <NotationSolution id="q-35">Das</NotationSolution>{" "}
        <NotationSolution id="q-36">Leben</NotationSolution>{" "}
        <NotationSolution id="q-37">findet</NotationSolution>{" "}
        <NotationSolution id="q-38">sich</NotationSolution>{" "}
        <NotationSolution id="q-39">aus</NotationSolution>{" "}
        <NotationSolution id="q-40">Harmonie</NotationSolution>{" "}
        <NotationSolution id="q-41">der</NotationSolution>{" "}
        <NotationSolution id="q-42">Zeiten</NotationSolution>,
      </p>
      <p>
        <NotationSolution id="q-43">Daß</NotationSolution>{" "}
        <NotationSolution id="q-44">immerdar</NotationSolution>{" "}
        <NotationSolution id="q-45">den</NotationSolution>{" "}
        <NotationSolution id="q-46">Sinn</NotationSolution>{" "}
        <NotationSolution id="q-47">Natur</NotationSolution>{" "}
        <NotationSolution id="q-48">und</NotationSolution>{" "}
        <NotationSolution id="q-49">Geist</NotationSolution>{" "}
        <NotationSolution id="q-50">geleiten</NotationSolution>,
      </p>
      <p>
        <NotationSolution id="q-51">Und</NotationSolution>{" "}
        <NotationSolution id="q-52">die</NotationSolution>{" "}
        <NotationSolution id="q-53">Vollkommenheit</NotationSolution>{" "}
        <NotationSolution id="q-54">ist</NotationSolution>{" "}
        <NotationSolution id="q-55">Eines</NotationSolution>{" "}
        <NotationSolution id="q-56">in</NotationSolution>{" "}
        <NotationSolution id="q-57">dem</NotationSolution>{" "}
        <NotationSolution id="q-58">Geiste</NotationSolution>,
      </p>
      <p>
        <NotationSolution id="q-59">So</NotationSolution>{" "}
        <NotationSolution id="q-60">findet</NotationSolution>{" "}
        <NotationSolution id="q-61">vieles</NotationSolution>{" "}
        <NotationSolution id="q-62">sich,</NotationSolution>{" "}
        <NotationSolution id="q-63">und</NotationSolution>{" "}
        <NotationSolution id="q-64">aus</NotationSolution>{" "}
        <NotationSolution id="q-65">Natur</NotationSolution>{" "}
        <NotationSolution id="q-66">das</NotationSolution>{" "}
        <NotationSolution id="q-67">Meiste</NotationSolution>.
      </p>
      <br />
      <p>
        <NotationSolution id="q-68">d.</NotationSolution>{" "}
        <NotationSolution id="q-69">24</NotationSolution>{" "}
        <NotationSolution id="q-70">Mai</NotationSolution>{" "}
        <NotationSolution id="q-71">1758</NotationSolution>.
      </p>
      <br />
      <p>
        <NotationSolution id="q-72">Mit</NotationSolution>{" "}
        <NotationSolution id="q-73">Unterthänigkeit</NotationSolution>
      </p>
      <p>
        <NotationSolution id="q-74">Scardanelli</NotationSolution>.
      </p>
    </Poem>
  )
}

PoemFruehlingNotationSolution.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

PoemFruehlingNotationSolution.defaultProps = {
  size: [2, 3],
}

export default PoemFruehlingNotationSolution
